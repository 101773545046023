jQuery(document).ready(function ($) {

    $('.aboutUsSlider').slick({
        dots: false,
        infinite: false,
        autoplay: false,
        autoplaySpeed: 0,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: $('.aboutUsSlider__arrows-left'),
        nextArrow: $('.aboutUsSlider__arrows-right'),
    });

    $('.defaultSlider').slick({
        dots: false,
        infinite: false,
        autoplay: false,
        autoplaySpeed: 0,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: $('.aboutUsSlider__arrows-left'),
        nextArrow: $('.aboutUsSlider__arrows-right'),
    });

    $('.whyUsAltSlider').slick({
        dots: false,
        infinite: false,
        autoplay: false,
        autoplaySpeed: 0,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: $('.whyUsAltSlider__arrows-left'),
        nextArrow: $('.whyUsAltSlider__arrows-right'),
    });
});